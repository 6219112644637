//import {Image} from 'classes/content/Components';
import config from 'config.json';
import apiService from 'services/apiService';
import { GridLocation } from '../classes/content/GridLocation';

// type Props = {
//     //image : image,
//     fileName : string,
//     localBlobRef : string,
//     location: GridLocation
//     editImageSrc : Function
// }

const requestImageMediaLog = async (fileName : string, localBlobRef : string, location: GridLocation, setImageAttribute : Function) => {

    //const {fileName, localBlobRef, location, editImageSrc} = props;

    //Create file
    let newBlob = await fetch(localBlobRef).then(r => r.blob());
    let fileFromBlob = new File([newBlob], "default.jpg");
    const S3URL = config.S3Region + ".amazonaws.com/" + config.S3Bucket + "/site." + config.site + "/" + config.deployment;
    let filePath = S3URL + "/images/" + fileName;

    let requestObject = {
        "Subfolder": "images",
        "MediaType": "image",
        "Filename": fileName
    }

    var mediaLogCreationResponse = await apiService.post(config.gridslateUrl + "/cms/CreateMediaLog", requestObject);
    console.log(mediaLogCreationResponse);
    if (mediaLogCreationResponse.success) {
        //Upload file
        //Generic response will have a payload with a url, so need two payload checks
        var putS3Response = await apiService.put(mediaLogCreationResponse.payload.url, fileFromBlob)
        if (putS3Response.success) {
            //editImageSrc(location, mediaLogCreationResponse.payload.url);
            setImageAttribute(location, 'src', filePath);
        } else {
            console.log("Put to S3 error: ", putS3Response);
        }
    } else {    
        console.log("MediaLog request error: ", mediaLogCreationResponse);
    }
    
}
export default requestImageMediaLog;
    // apiService.post(config.apiUrl + "/slidecms/CreateMediaLog", requestObject)
    //     .then((data) => {
    //         if (!data.errorCaught) {

    //             //Upload file
    //             apiService.put(data.payload.url, fileFromBlob)
    //                 .then((data) => {
    //                     if (!data.errorCaught) {
    //                         //Update Component data with src path
    //                         //srcPath = config.S3BucketURL + "images/" + fileName;
    //                         dispatch(editdata({ componentId: Component.componentRef, target: 'src', value: filePath }))
    //                         //return filePath;
    //                         console.log("Put to S3 success: ", data);
    //                     } else {
    //                         console.log("Put to S3 error: ", data);
    //                     }
    //                 })
    //         } else {
    //             console.log("MediaLog request error: ", data);
    //         }

        //})

    

// for (let x in components) {
//     if (components[x].type === 'image' && components[x].localBlobRef !== null && components[x].src === '') {
//         // let fileSrc = await requestMediaUpload("image", Component.fileName, files[0], Component.componentRef);
//         // if (fileSrc !== null) {
//         //     dispatch(editdata({ componentId: componentRef, target: 'src', value: fileSrc }))
//         // }
//         //console.log(components[x].localBlobRef, components[x].src);
//         requestMediaLogFunction("image", components[x].fileName, components[x]);
//         dispatch(editdata({ componentId: components[x].componentRef, target: 'src', value: 'fetching' }))

//         //dispatch(editdata({ componentId: components[x].id, target: 'src', value: components[x].data.localSrc }))
//     }
// }

// }, [components])

