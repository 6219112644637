class StyleItemOption {
    name: string;
    values: string[];
    defaultValue: string;
    type: string;

    constructor(name: string, values: string[], defaultValue: string, type: string) {
        this.name = name;
        this.values = values;
        this.defaultValue = defaultValue;
        this.type = type;
    }
}

export default StyleItemOption;