//import http from "./httpService";
import config from "config.json";
import jwtDecode from "jwt-decode";

const apiEndpoint = config.apiUrl + "accounts/";

export function checkAccessTokenStillValid() {
  const expiration = localStorage.getItem("expirationTime");
  if (expiration === "null" || expiration === undefined) return false;
  if (Date.now() / 1000 >= expiration) return false;
  else return true;
}

// export async function refreshAccessToken() {
  
//   const refreshToken = localStorage.getItem("refreshToken");
//   const { data: result } = await http.post(apiEndpoint + "refreshaccesstoken", {
//     RefreshToken: refreshToken
//   });

//   return result;
// }

export function decodeToken() {
  const accessToken = localStorage.getItem("accessToken");
  let user = null;

  try {
    const decodedToken = jwtDecode(accessToken);
    user = {
      username: decodedToken["use"],
      email: decodedToken["ema"],
      claims: decodedToken["rol"],
      tes: decodedToken["tes"],
      refreshExpirationTime: decodedToken["exp"] * 1000,
    };

    // console.log(
    //   new Intl.DateTimeFormat("en-US", {
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    //   })
    //     .format(decodedToken["exp"] * 1000)
    // );

    return user;
    //console.log(user);
    //this.setState({ user: user });
  } catch (error) {
    //Token doesn't exist probably
    //console.log(error);
  }
  
}