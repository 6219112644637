import createGUID from 'utility/helper-functions/createGUID';
import { COMPONENT_TYPE } from 'core/enums/component-types';
import { STATUS } from 'core/enums/status';
import styleLogic from 'core/componentStyleLogic';

// interface styleSetting {
//     [key: string]: string;
// }

// let defaultStyleSettings: styleSetting = {};
// defaultStyleSettings['fontSize'] = 'base';
// defaultStyleSettings['color'] = '#000000';
// defaultStyleSettings['bold'] = 'normal';
// defaultStyleSettings['underline'] = 'non-italic';
// defaultStyleSettings['alignment'] = 'left';
// defaultStyleSettings['margin'] = '0';
// defaultStyleSettings['padding'] = '0';
// defaultStyleSettings['border'] = '0';
// defaultStyleSettings['width'] = '1';
// defaultStyleSettings['height'] = '1';

class Component {

    id : string = "";
    type: COMPONENT_TYPE;
    text: string;
    style: {};
    status: STATUS;
    componentRef: string;
    width: number;

    constructor(type: COMPONENT_TYPE) {
        this.type = type;
        this.text = (type === COMPONENT_TYPE.text) ? '<p>text</p>' : type;
        //this.style = { fontSize: '20px', color: 'black' };
        this.style = styleLogic.setDefaultStyle(type);
        this.status = STATUS.new;
        this.componentRef = createGUID(8);
        this.width = 0;
    }

}

class ComponentDTO {

    id: string = "";
    pageRef: string = "";
    pageId : string = ""
    componentRef: string = "";
    stringifiedComponent: string = "";
    type: string = "";

    constructor() {
        
    }
}

class Image extends Component {

    src: string;
    localBlobRef: string;
    fileName: string;
    //text could be alttext

    constructor() {
        super(COMPONENT_TYPE.image);
        this.src = '';
        this.localBlobRef = '';
        this.fileName = createGUID(8);
    }
}

class mcqQuestion extends Component {

    constructor() {
        super(COMPONENT_TYPE.mcqQuestion);
    }
}

class mcqAnswer extends Component {

    //id: string = "";
    componentRef: string = createGUID(8);
    isCorrect: boolean = false;

    constructor() {
        super(COMPONENT_TYPE.mcqAnswer);
    }
}

export { Component, ComponentDTO, Image, mcqQuestion, mcqAnswer };