import sanitizeHtml from 'sanitize-html';
//import { COMPONENT_TYPE } from '../views/slideshow-constructor/enums';

const sanitizeConf = {
    //allowedTags: ["b", "i", "em", "strong", "a", "u", "p", "code", "li", "ul", "div"],
    allowedTags: ["b", "i", "em", "strong", "a", "u", "p", "li", "ul", "br"],
    allowedAttributes: { a: ["href"] }
};

const sanitizeText = (content: string) => {
    //console.log('Incoming text:', content);
    let sanitizedContent = sanitizeHtml(content, sanitizeConf);
    return sanitizedContent;
};

const cleanListInnerHtml = (content: string) => {
    //remove all empty list items
    content = content.replaceAll('<li></li>', '');

    if (content.length > 3 && content.substring(0, 4) !== '<li>') {
        //console.log("Inner 4 characters:", content.substring(0, 4));
        content = '<li>' + content;
    }
    //Convert all div tags to li
    content = content.replaceAll('<div>', '<li>');

    //Santize innerText
    content = sanitizeText(content);
    //console.log(innerText); 
    return content;
}

const cleanTextInnerHtml = (content: string) => {
    //Santize innerText
    content = content.replaceAll('<div>', '<p>');

    //Replace empty paragraph tags with line breaks
    content = content.replaceAll('<p></p>', '<br />');

    //if text does not start with paragraph tag, then add an opening and closing paragraph tag to all characters
    //leading to the first paragraph tag
    if (content.length > 3 && content.substring(0, 3) !== '<p>') {
        //find first paragraph tag
        let firstParagraph = content.indexOf('<p>');
        //add paragraph tags to all characters leading to the first paragraph tag
        content = '<p>' + content.substring(0, firstParagraph) + '</p>' + content.substring(firstParagraph);
    }

    content = sanitizeText(content);
    return content;
}

export default { cleanListInnerHtml, cleanTextInnerHtml };




