//React
import { useEffect } from 'react';
import { useDispatch } from "react-redux";

//Helpers/Services
import { decodeToken } from 'services/tokenService';
import { setDecodedToken, setLoggedIn } from "store/store";

//Views/Components

const UserLogic = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    let user = decodeToken();
    if (user != null) {
      dispatch(setDecodedToken({ userToken: user }));
    } else {
      dispatch(setLoggedIn({ loggedIn: false }));
    }

  });

  return (<div></div>);
}

export default UserLogic;