import { useState, useEffect } from "react";
//import config from 'config.js';
//import pageLoadParser from "components/constructor/helper/pageLoadParser";

//TODO: Re-check AbortController for latest version of React
const useS3Fetch = (url, data) => {

    const [payload, setPayload] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsPending(true);
        
        //const abortCont = new AbortController();
        fetch(url, {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
              },
        })
        //.then(response => console.log(response.json()))
        .then((response) => {return response.json()})
        .then((data) => {
            setIsPending(false);
            //console.log(data);
            //let dataObject = {"payload" : data};
            //dataObject = pageLoadParser(dataObject);               
            setPayload(data);    
          })
        .catch((error) => {
            setError(error);
        });
      
    }, [url])


    return {payload, isPending, error}
}

export default useS3Fetch;
