
//import { toast } from "react-toastify";
import { ResponseObject } from "classes/services/ResponseObject";

// type httpResponse = {
//     payload: any,
//     errorCaught: boolean,
//     statusCode: number,
//     message: string
// }

class requestHeaders {
    authorization: string;
    refresh: string;

    constructor() {
        this.authorization = localStorage.getItem("accessToken") || "";
        this.refresh = localStorage.getItem("refreshToken") || "";
    }
}

//Try new Get
const get = async (url: string): Promise<ResponseObject> => {
    const headers = new requestHeaders();
    let responseObj = new ResponseObject();
    let response;
    try {
    response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': headers.authorization,
            'Refresh': headers.refresh
        }
    });
    }
    catch (e) {
        //console.log(e);
        responseObj.success = false;
        responseObj.message = JSON.stringify(e);
        responseObj.errorSource = "Client GET Error";
        return responseObj;
    }

    let dataResponse = null;

    if (response.ok) {
        dataResponse = await response.json();
        responseObj.statusCode = response.status;
        responseObj.message = dataResponse.message;
        responseObj.payload = dataResponse.payload;
        responseObj.success = dataResponse.success;
    } else {
        responseObj.success = false;
        responseObj.statusCode = response.status;
        responseObj.message = JSON.stringify(response);
        responseObj.errorSource = "Server problem";
    }

    //console.log("getting here: ", responseObj);
    return responseObj;
}

//Original Get
// const get = async (url: string): Promise<ResponseObject> => {
//     const headers = new requestHeaders();
//     let responseObj = new ResponseObject();

//     try {
//         let response = await fetch(url, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': headers.authorization,
//                 'Refresh': headers.refresh
//             }
//         });

//         let dataResponse = null;

//         if (response.ok) {
//             dataResponse = await response.json();
//             responseObj.statusCode = response.status;
//             responseObj.message = dataResponse.message;
//             responseObj.payload = dataResponse.payload;
//             responseObj.success = dataResponse.success;
//         } else {
//             responseObj.success = false;
//             responseObj.statusCode = response.status;
//             responseObj.message = JSON.stringify(response);
//             responseObj.errorSource = "Server problem";
//         }



//     }
//     catch (e) {
//         console.log(e);
//         responseObj.success = false;
//         responseObj.message = JSON.stringify(e);
//         //responseObj.statusCode = e
//         responseObj.errorSource = "Client GET Error";
//     }

//     return responseObj;
// }

// const get = async (url: string): Promise<ResponseObject> => {
//     const headers = new requestHeaders();
//     let responseObj = new ResponseObject();


//     await fetch(url, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': headers.authorization,
//                 'Refresh': headers.refresh
//             }
//         })
//         .then(response => {
//             if (!response.ok) {
//                 throw new Error(response.status.toString());
//             }
//             return response;
//         })
//         .catch(error => {
//             console.log(error);
//             responseObj.success = false;
//             responseObj.message = JSON.stringify(error);
//             responseObj.errorSource = "Client GET Error";
//         });

//     return responseObj;
// }

// fetch("some-url")
//     .then(function(response)
//      {
//       if(response.status!==200)
//        {
//           throw new Error(response.status)
//        }
//      })
//     .catch(function(error)
//     {
//       ///if status code 401...
//     });

const post = async (url: string, data: object): Promise<ResponseObject> => {
    const headers = new requestHeaders();

    // let responseObj: httpResponse = {
    //     payload: null,
    //     errorCaught: false,
    //     statusCode: 0,
    //     message: ""
    // }

    // let responseObj = {
    //     payload: null,
    //     errorCaught: false,
    //     statusCode: 0,
    //     message: "",
    //     errorSource : ""
    // }

    let responseObj = new ResponseObject();

    try {
        let response = await fetch(url, {
            method: 'POST',
            
            headers: {
                'Content-Type': 'application/json',
                // 'access-control-allow-origin': '*',
                // 'access-control-request-method': 'POST',
                // 'access-control-request-headers': 'content-type',
                'Authorization': headers.authorization,
                'Refresh': headers.refresh
            },
            body: JSON.stringify(data)
        });

        const dataResponse = await response.json();

        responseObj.statusCode = response.status;
        responseObj.message = dataResponse.message;
        responseObj.payload = dataResponse.payload;
        responseObj.success = dataResponse.success;

        // responseObj = {
        //     payload: dataResponse.payload,
        //     errorCaught: false,
        //     statusCode: response.status,
        //     message: dataResponse.message
        // }
    }
    catch (e) {
        console.log(e);
        responseObj.success = true;
        responseObj.message = JSON.stringify(e);
        responseObj.errorSource = "Client POST Error";
    }

    return responseObj;
}

//S3 - will not return a GenericResponse, so no double payload
const put = async (url: string, data: any): Promise<ResponseObject> => {

    let responseObj = new ResponseObject();

    //const headers = new requestHeaders();
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'image/jpeg/png',
        },
        body: data
    });

    let dataResponse;

    try {
        dataResponse = await response.json();
    }
    catch (e) {
        console.log(e);
        dataResponse = null;
    }

    //If response is not empty then set payload to response.json() else set it to null
    //const payload = response.status !== 204 ? response.json() : null;
    console.log(response);
    //let payload = null;

    // let responseObj: httpResponse = {
    //     payload: dataResponse ? dataResponse.payload: null,
    //     errorCaught: false,
    //     statusCode: response.status,
    //     message: ""
    // }
    responseObj.statusCode = response.status;
    responseObj.payload = dataResponse ? dataResponse.payload : null;

    return responseObj;
}

export default {
    get,
    post,
    put
}