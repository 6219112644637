//import { COMPONENT_TYPE } from 'core/enums/component-types';
import { SelectedComponentData } from 'classes/content/SelectedComponentData';
import { useEffect, useState } from 'react';
import { Label, RangeSlider } from 'flowbite-react';
import StyleSetting from 'classes/style/StyleSetting';
import StyleItemOption from 'classes/style/StyleItemOption';
import styleLogic from 'core/componentStyleLogic';
import Row from 'components/helper/Row';

//let style: StyleSetting = {};

type Props = {
    selectedComponent: SelectedComponentData;
    componentStyle: StyleSetting;
    setComponentStyle: Function;
}

const StyleOverviewPanel = (props: Props) => {

    const styleItemOptions = styleLogic.returnStyleItemOptions();
    let { selectedComponent, componentStyle, setComponentStyle } = props;  
    const [selectedStyleIcon, setSelectedStyleIcon] = useState(-1);

    const returnIcon = (styleItem: string) => {
        switch (styleItem) {
            case "fontSize":
                return <svg className="w-[32px] h-[32px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M3 6.2V5h11v1.2M8 5v14m-3 0h6m2-6.8V11h8v1.2M17 11v8m-1.5 0h3" />
                </svg>
            case "color":
                return <div>
                <input type="color" value={componentStyle["color"]} onChange={(e) => {
                    let tempComponentStyle = { ...componentStyle };
                    tempComponentStyle["color"] = e.target.value;
                    setComponentStyle(selectedComponent.componentRef, tempComponentStyle);
                }} />
                </div>
                // return <svg className="w-[32px] h-[32px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                //     <path fillRule="evenodd" d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z" clipRule="evenodd" />
                // </svg>
            case "background":
                return <svg className="w-[32px] h-[32px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path fillRule="evenodd" d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z" clipRule="evenodd" />
                </svg>
            case "bold":
                return <svg className="w-[32px] h-[32px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M8 5h4.5a3.5 3.5 0 1 1 0 7H8m0-7v7m0-7H6m2 7h6.5a3.5 3.5 0 1 1 0 7H8m0-7v7m0 0H6" />
                </svg>
            case "italics":
                return <svg className="w-[32px] h-[32px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="m8.874 19 6.143-14M6 19h6.33m-.66-14H18"/>
              </svg>
              
            case "decoration":
                return <svg className="w-[32px] h-[32px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeWidth="1" d="M6 19h12M8 5v9a4 4 0 0 0 8 0V5M6 5h4m4 0h4" />
                </svg>

            case "alignment":
                return <svg className="w-[32px] h-[32px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" d="M8 6h8M6 10h12M8 14h8M6 18h12" />
                </svg>
            case "margin":
                return <p>Margin</p>
            case "padding":
                return <p>Padding</p>
            case "border":
                return <p>Border</p>
            case "width":
                return <p>Width</p>
                
            case "height":
               return <p>Hieght</p>
            default:
                <></>
        }
    }

    //let styleItems = ["fontSize", "color", "bold", "underline", "alignment", "margin", "padding", "border", "width", "height"];

    //Create key value pairs for each style item
    // interface styleSetting {
    //     [key: string]: string;
    // }

    // let styleSettings: styleSetting = {};
    // styleSettings['fontSize'] = 'base';
    // styleSettings['color'] = '#000000';
    // styleSettings['bold'] = 'normal';
    // styleSettings['underline'] = 'non-italic';
    // styleSettings['alignment'] = 'left';
    // styleSettings['margin'] = '0';
    // styleSettings['padding'] = '0';
    // styleSettings['border'] = '0';
    // styleSettings['width'] = '1';
    // styleSettings['height'] = '1';

    //const componentTypeStyle = "inline-block justify-between p-2 border-2 border-slate-50 m-2 w-32 h-16";

    const returnBorder = (styleItem: string) => {
        let thisItem = styleItemOptions.find((item) => item.name === styleItem);
        if (thisItem === undefined) {
            return ' border-2 border-white';
        }

        if (thisItem.type === "click" && componentStyle[styleItem] !== thisItem.defaultValue) {
            return " border-2 border-slate-50 bg-red-500";
        }
    }

    const handleStyleIconClick = (index: number) => {
        //If no component is selected, return
        if (!selectedComponent || !selectedComponent.componentRef) {
            return;
        }

        let componentRef = selectedComponent.componentRef;

        setSelectedStyleIcon(index);

        let styleItemClickedUpon = styleItemOptions[index].name;
        let thisItem = styleItemOptions.find((item) => item.name === styleItemClickedUpon);
        if (thisItem === undefined) {
            return;
        }

        if (thisItem.type === "click") {
            console.log(componentStyle[styleItemClickedUpon], thisItem.defaultValue);
            if (componentStyle[styleItemClickedUpon] === thisItem.defaultValue) {            
                let tempComponentStyle = { ...componentStyle };
                tempComponentStyle[styleItemClickedUpon] = thisItem.values[1];
                setComponentStyle(componentRef, tempComponentStyle);
            } else {
                let tempComponentStyle = { ...componentStyle };
                tempComponentStyle[styleItemClickedUpon] = thisItem.defaultValue;
                setComponentStyle(componentRef, tempComponentStyle);
            }
        }

        //Update the style of the selected component
        //let newStyle = selectedComponent.style;
    }

    return (
        <div className='rounded border-2 bg-slate-300'>
            <Row>
            {/* {JSON.stringify(componentStyle)} */}
            </Row>
            {/* <svg className="w-[48px] h-[48px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" d="M8 6h8M6 10h12M8 14h8M6 18h12" />
            </svg> */}

            {styleItemOptions.map((styleItemOption, index) => {
                return (
                    <div key={'icon_' + index}
                        className={'inline-block m-2 align-middle ' + returnBorder(styleItemOption.name)}
                        onClick={() => handleStyleIconClick(index)}
                    >
                        {returnIcon(styleItemOption.name)}
                    </div>
                )
            })}
            {selectedStyleIcon !== -1 && styleItemOptions[selectedStyleIcon].type === "slider" &&
                <div className="mb-1 block">
                    <Label className='text-4xl' htmlFor="default-range" value={styleItemOptions[selectedStyleIcon].name + " : " + componentStyle[styleItemOptions[selectedStyleIcon].name]} />
                    <RangeSlider 
                        min="0"
                        max={"" + (styleItemOptions[selectedStyleIcon].values.length - 1)} 
                        value={styleItemOptions[selectedStyleIcon].values.indexOf(componentStyle[styleItemOptions[selectedStyleIcon].name])}
                        onChange={(e) => {
                            let tempComponentStyle = { ...componentStyle };
                            tempComponentStyle[styleItemOptions[selectedStyleIcon].name] = styleItemOptions[selectedStyleIcon].values[parseInt(e.target.value)];
                            setComponentStyle(selectedComponent.componentRef, tempComponentStyle);
                        }}
                        id="default-range" />
                </div>
            }

        </div>
    )
}

export default StyleOverviewPanel;