import {Component} from 'classes/content/Components';
import createGUID from 'utility/helper-functions/createGUID';

class Page
{
    id : string = "";
    parentId : string = "";
    parentType : string = "website";
    title : string = "";
    pageRef : string = createGUID(10);
    description : string = "";
    ownerId: string = "";
    creationDate: string = "";
    lastUpdateDate: string = "";
    gridArray : string[][] = [];

    constructor(parentId: string, title: string)
    {
        this.parentId = parentId;
        this.title = title;
    }
}

export default Page;