


const returnFontSizeClass = (fontSize: string, viewBreakpoint: string) => {

    if (viewBreakpoint === 'sm' || viewBreakpoint === 'md') {
        switch (fontSize) {
            case 'xs':
                return 'text-xs ';
            case 'sm':
                return 'text-sm ';
            case 'base':
                return 'text-sm ';
            case 'lg':
                return 'text-sm ';
            case 'xl':
                return 'text-xs ';
            case '2xl':
                return 'text-base ';
            case '3xl':
                return 'text-xl ';
            case '4xl':
                return 'text-xl ';
            case '5xl':
                return 'text-2xl ';
            case '6xl':
                return 'text-2xl ';
            default:
                return 'text-base ';
        }
    }
    else {
        switch (fontSize) {
            case 'xs':
                return 'text-xs ';
            case 'sm':
                return 'text-sm ';
            case 'base':
                return 'text-base ';
            case 'lg':
                return 'text-lg ';
            case 'xl':
                return 'text-xl ';
            case '2xl':
                return 'text-2xl ';
            case '3xl':
                return 'text-3xl ';
            case '4xl':
                return 'text-4xl ';
            case '5xl':
                return 'text-5xl ';
            case '6xl':
                return 'text-6xl ';
            default:
                return 'text-base ';
        }
    }
}

const returnWidthClass = (width: string, viewBreakpoint: string) => {
    
    if (viewBreakpoint === 'sm' || viewBreakpoint === 'md') {
        switch (width) {
            case 'fit':
                return 'w-full ';
            case '1':
                return 'w-4/12 ';
            case '2':
                return 'w-4/12 ';
            case '3':
                return 'w-4/12 ';
            case '4':
                return 'w-4/12 ';
            case '5':
                return 'w-6/12 ';
            case '6':
                return 'w-7/12 ';
            case '7':
                return 'w-8/12 ';
            case '8':
                return 'w-10/12 ';
            case '9':
                
                return 'w-11/12 ';
            case '10':
                return 'w-11/12 ';
            case '11':
                return 'w-11/12 ';
            case '12':
                return 'w-11/12 ';
            default:
                return 'w-full ';
        }
    } else {
        switch (width) {
            case 'fit':
                return 'w-full ';
            case '1':
                return 'w-1/12 ';
            case '2':
                return 'w-2/12 ';
            case '3':
                return 'w-3/12 ';
            case '4':
                return 'w-4/12 ';
            case '5':
                return 'w-5/12 ';
            case '6':
                return 'w-6/12 ';
            case '7':
                return 'w-7/12 ';
            case '8':
                return 'w-8/12 ';
            case '9':
                return 'w-9/12 ';
            case '10':
                return 'w-10/12 ';
            case '11':
                return 'w-11/12 ';
            case '12':
                return 'w-full ';
            default:
                return 'w-full ';
        }
    }

}

const returnHeightClass = (height: string) => {
    switch (height) {
        case 'fit':
            return 'h-fit';
        case '1':
            return 'h-1/12 ';
        case '2':
            return 'h-2/12 ';
        case '3':
            return 'h-3/12 ';
        case '4':
            return 'h-4/12 ';
        case '5':
            return 'h-5/12 ';
        case '6':
            return 'h-6/12 ';
        default:
            return 'h-1/12 ';
    }
}

const returnBorderClass = (border: string) => {
    switch (border) {
        case '0':
            return 'border-0 ';
        case '1':
            return 'border-2 ';
        case '2':
            return 'border-4 ';
        case '3':
            return 'border-8 ';
        case '4':
            return 'border ';
        default:
            return 'border-0 ';
    }
}

const returnColorClass = (color: string) => {
    switch (color) {
        case 'black':
            return 'text-black ';
        case 'white':
            return 'text-white ';
        case 'gray':
            return 'text-gray-500 ';
        case 'red':
            return 'text-red-500 ';
        case 'yellow':
            return 'text-yellow-500 ';
        case 'green':
            return 'text-green-500 ';
        case 'blue':
            return 'text-blue-500 ';
        case 'indigo':
            return 'text-indigo-500 ';
        case 'purple':
            return 'text-purple-500 ';
        case 'pink':
            return 'text-pink-500 ';
        default:
            return 'text-black ';
    }
}

const returnBackgroundClass = (color: string) => {
    switch (color) {
        case 'black':
            return 'bg-black ';
        case 'white':
            return 'bg-white ';
        case 'gray':
            return 'bg-gray-500 ';
        case 'red':
            return 'bg-red-500 ';
        case 'yellow':
            return 'bg-yellow-500 ';
        case 'green':
            return 'bg-green-500 ';
        case 'blue':
            return 'bg-blue-500 ';
        case 'indigo':
            return 'bg-indigo-500 ';
        case 'purple':
            return 'bg-purple-500 ';
        case 'pink':
            return 'bg-pink-500 ';
        default:
            return 'bg-white ';
    }
}

const returnAlignmentClass = (alignment: string) => {
    switch (alignment) {
        case 'left':
            return 'text-left ';
        case 'center':
            return 'text-center ';
        case 'right':
            return 'text-right ';
        default:
            return 'text-left ';
    }
}

const returnMarginClass = (margin: string) => {
    switch (margin) {
        case '0':
            return 'my-0 ';
        case '1':
            return 'my-1 ';
        case '2':
            return 'my-2 ';
        case '3':
            return 'my-3 ';
        case '4':
            return 'my-4 ';
        case '5':
            return 'my-5 ';
        case '6':
            return 'my-6 ';
        case '8':
            return 'my-8 ';
        case '10':
            return 'my-10 ';
        case '12':
            return 'my-12 ';
        case '16':
            return 'my-16 ';
        case '20':
            return 'my-20 ';
        case '24':
            return 'my-24 ';
        case '32':
            return 'my-32 ';
        case '40':
            return 'my-40 ';
        case '48':
            return 'my-48 ';
        case '56':
            return 'my-56 ';
        case '64':
            return 'my-64 ';
        default:
            return 'm-0 ';
    }
}

const returnPaddingClass = (padding: string) => {
    switch (padding) {
        case '0':
            return 'p-0 ';
        case '1':
            return 'p-1 ';
        case '2':
            return 'p-2 ';
        case '3':
            return 'p-3 ';
        case '4':
            return 'p-4 ';
        case '5':
            return 'p-5 ';
        case '6':
            return 'p-6 ';
        case '8':
            return 'p-8 ';
        case '10':
            return 'p-10 ';
        case '12':
            return 'p-12 ';
        case '16':
            return 'p-16 ';
        case '20':
            return 'p-20 ';
        case '24':
            return 'p-24 ';
        case '32':
            return 'p-32 ';
        case '40':
            return 'p-40 ';
        case '48':
            return 'p-48 ';
        case '56':
            return 'p-56 ';
        case '64':
            return 'p-64 ';
        default:
            return 'p-0 ';
    }
}

const returnBoldClass = (bold: string) => {
    switch (bold) {
        case 'none':
            return 'font-normal ';
        case 'bold':
            return 'font-bold ';
        default:
            return 'font-normal ';
    }
}

const returnItalicClass = (italic: string) => {
    switch (italic) {
        case 'none':
            return 'italic ';
        case 'italic':
            return 'italic ';
        default:
            return 'italic ';
    }
}

const returnUnderlineClass = (underline: string) => {
    switch (underline) {
        case 'none':
            return 'underline ';
        case 'underline':
            return 'underline ';
        default:
            return 'underline ';
    }
}

const returnDecorationClass = (decoration: string) => {
    switch (decoration) {
        case 'none':
            return '';
        case 'line-through':
            return 'line-through ';
        default:
            return '';
    }
}

const returnStyleItemOptions = (styleItem: string) => {
    switch (styleItem) {
        case 'fontSize':
            return ['xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl'];
        case 'color':
            return ['black', 'white', 'gray', 'red', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink'];
        case 'background':
            return ['none', 'black', 'white', 'gray', 'red', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink'];
        case 'bold':
            return ['none', 'bold'];
        case 'italic':
            return ['none', 'italic'];
        case 'underline':
            return ['none', 'underline'];
        case 'decoration':
            return ['none', 'line-through'];
        case 'alignment':
            return ['left', 'center', 'right'];
        case 'margin':
            return ['0', '1', '2', '3', '4', '5', '6', '8', '10', '12', '16', '20', '24', '32', '40', '48', '56', '64'];
        case 'padding':
            return ['0', '1', '2', '3', '4', '5', '6', '8', '10', '12', '16', '20', '24', '32', '40', '48', '56', '64'];
        case 'border':
            return ['0', '1', '2', '3', '4'];
        case 'width':
            return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        case 'height':
            return ['1', '2', '3', '4', '5', '6'];
        default:
            return [];
    }
}

export default { returnFontSizeClass, returnWidthClass, returnHeightClass, returnBorderClass, returnColorClass, returnBackgroundClass, returnAlignmentClass, returnMarginClass, returnPaddingClass, returnBoldClass, returnItalicClass, returnUnderlineClass, returnDecorationClass, returnStyleItemOptions };