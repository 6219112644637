//import { Component } from 'classes/slideshow-constructor/components';
//import { SelectedComponentData } from '../../classes/SelectedComponentData';
import {COMPONENT_TYPE} from './enums/component-types';
import styleLogic from 'core/componentStyleLogic';
import ImageComponent from 'components/ImageComponent';
import { useWindowDimensions } from "utility/hooks/useWindowDimensions";
// import Editor from 'react-simple-code-editor';
// import { highlight, languages } from 'prismjs';

//import { ReactFitty } from "react-fitty";

// type Props = {
//     Component: Component;
//     rowIndex: number;
//     colIndex: number;
//     setComponentTextHandler: (type: COMPONENT_TYPE, componentRef: string, rowIndex: number, colIndex: number) => void;
//     handleDrop: (type: COMPONENT_TYPE, rowIndex: number, colIndex: number) => void;
//     setImageAttribute: (targetAttribute: string, value: string) => void;
//     handleMcqAnswerClick: () => void;
// }

const ComponentRenderer = (props: any) => {

    const { Component, rowIndex, colIndex, setComponentTextHandler, handleDrop, setImageAttribute, handleMcqAnswerClick } = props;

    const { viewHeight, viewWidth, viewBreakpoint } = useWindowDimensions();
    // const hightlightWithLineNumbers = (input: string, language: any) =>
    // highlight(input, {}, language)
    //     .split("\n")
    //     .map((line: string, i: number) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
    //     .join("\n");

    const clean = (data: string) => {
        data = data.replaceAll('&lt;', '<');
        data = data.replaceAll('&gt;', '>');
        return data;
    }

    const onDrop = (e: any) => {
        e.preventDefault();
        handleDrop(e.dataTransfer.getData('type'), rowIndex, colIndex);
        //console.log('dropped');
    }

    //TODO: implement Component.style when it is implemented in the Component class

    // <div className='center-text'>

    const getDefaultComponentClass = (type: COMPONENT_TYPE) => {
        // border: 2px solid lightgray;
        // padding: 5px;
        // margin: 5px;
        // cursor: pointer;
        // width: 100%;
        // min-height: 75px;
        return 'border 2 border-slate-50 p-2 m-2 cursor-pointer w-full min-h-20 h-fit';
    }

    return (
        <div>
            {Component.type === COMPONENT_TYPE.title &&
                //<ReactFitty>
                    <div
                        id={Component.componentRef}
                        className={getDefaultComponentClass(Component.type) + styleLogic.parseStyle(Component.style, viewBreakpoint)}
                        suppressContentEditableWarning
                        contentEditable={true}
                        //style={styleLogic.getDefaultStyle(Component.type)}
                        onBlur={() => setComponentTextHandler(Component.type, Component.componentRef, rowIndex, colIndex)}
                    >
                        {Component.text}
                    </div>
                //</ReactFitty>
            }
            {Component.type === COMPONENT_TYPE.heading &&
                <div
                    id={Component.componentRef}
                    className={getDefaultComponentClass(Component.type)  + styleLogic.parseStyle(Component.style, viewBreakpoint)}
                    suppressContentEditableWarning
                    contentEditable={true}
                    //style={styleLogic.getDefaultStyle(Component.type)}
                    onBlur={() => setComponentTextHandler(Component.type, Component.componentRef, rowIndex, colIndex)}
                >
                    {Component.text}
                </div>
            }
            {Component.type === COMPONENT_TYPE.text &&
                <div
                    id={Component.componentRef}
                    className={getDefaultComponentClass(Component.type)  + styleLogic.parseStyle(Component.style, viewBreakpoint)}
                    suppressContentEditableWarning
                    contentEditable={true}
                    //style={styleLogic.getDefaultStyle(Component.type)}
                    onBlur={() => setComponentTextHandler(Component.type, Component.componentRef, rowIndex, colIndex)}
                    dangerouslySetInnerHTML={{ __html: clean(Component.text) }}
                />

            }
            {Component.type === COMPONENT_TYPE.image &&
                <ImageComponent
                    style={styleLogic.getDefaultStyle(Component.type)}
                    classString={styleLogic.parseImageStyle(Component.style, viewBreakpoint)}
                    setImageAttribute={(target, value) => setImageAttribute(target, value)}
                    image={Component}
                />

            }
            {Component.type === COMPONENT_TYPE.list &&
                <ul className="list-disc ml-4 list-outside">
                    <div
                        id={Component.componentRef}
                        className={getDefaultComponentClass(Component.type)}
                        suppressContentEditableWarning
                        contentEditable={true}
                        style={styleLogic.getDefaultStyle(Component.type)}
                        onBlur={() => setComponentTextHandler(Component.type, Component.componentRef, rowIndex, colIndex)}
                        dangerouslySetInnerHTML={{ __html: clean(Component.text) }}
                    />
                </ul>
            }
            {Component.type === COMPONENT_TYPE.empty &&
                <div
                    onDrop={(e) => onDrop(e)}
                    onDragOver={(e) => e.preventDefault()}
                >
                    <div
                        id={Component.componentRef}
                        className={getDefaultComponentClass(Component.type)}
                    >
                        Empty
                    </div>
                </div>
            }
            {Component.type === COMPONENT_TYPE.mcqQuestion &&
                <div
                    id={Component.componentRef}
                    className={getDefaultComponentClass(Component.type)}
                    suppressContentEditableWarning
                    contentEditable={true}
                    style={styleLogic.getDefaultStyle(Component.type)}
                    onBlur={() => setComponentTextHandler(Component.type, Component.componentRef, rowIndex, colIndex)}
                >
                    {Component.text}
                </div>
            }
            {Component.type === COMPONENT_TYPE.mcqAnswer &&
                // <Stack direction="horizontal">
                <div>
                    <div className='center' onClick={handleMcqAnswerClick}>
                        {Component.isCorrect && <div className='mcqanswer-tickbox-true'>T</div>}
                        {!Component.isCorrect && <div className='mcqanswer-tickbox-false'>F</div>}
                    </div>
                    <div
                        id={Component.componentRef}
                        className={getDefaultComponentClass(Component.type)}
                        suppressContentEditableWarning
                        contentEditable={true}
                        style={styleLogic.getDefaultStyle(Component.type)}
                        onBlur={() => setComponentTextHandler(Component.type, Component.componentRef, rowIndex, colIndex)}
                    >
                        {Component.text}
                    </div>
                 {/* </Stack> */}
                </div>
            }
            {/* {Component.type === COMPONENT_TYPE.codeblock &&
            <Editor
                value={Component.value}
                padding={10}
                onValueChange={(code : string) => console.log(code)}
                className="code-editor"
                highlight={(sourceCode) => hightlightWithLineNumbers(sourceCode, languages.js)}
                // textareaId="codeArea"
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 18,
                    backgroundColor: 'white',
                    outline: 0
                }}
            />
        } */}
        </div>
    )
}

export default ComponentRenderer;