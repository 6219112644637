//React
import { useState, useEffect } from "react";
import { Container } from 'components/helper/HelperComponents';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

//Helpers/Services
import config from 'config.json';
//import apiService from "services/apiService";
import Page from "classes/gridslate/Page";
import { Component, ComponentDTO, Image } from "classes/content/Components";
import useS3Fetch from "services/useS3Fetch";
//import PageDTO from "classes/gridslate/PageDTO";
import LoadPageResponseModel from "classes/gridslate/LoadPageResponseModel";
import styleLogic from "core/componentStyleLogic";
import { useWindowDimensions } from "utility/hooks/useWindowDimensions";

//Bounce, Fade, Flip, Hinge, JackInTheBox, Roll, Rotate, Slide and Zoom

import { Fade, Flip, Hinge, Bounce, JackInTheBox, Roll, Rotate, Slide } from "react-awesome-reveal";
//import { Fade } from "react-awesome-reveal";
//import http from 'services/httpService.js';
//import pageLoadParser from "utils/constructor/pageLoadParser";

//import { highlight, languages } from "prismjs/components/prism-core";
//import { toast } from 'react-toastify';

//Views/Components
//import Editor from 'react-simple-code-editor';
//import CodeTryItViewer from "components/page/CodeTryItViewer";
//import ProgressViewer from "components/page/ProgressViewer";

type Props = {
    pageRef: string;
    pageId: string;
}

const ViewPage = (props: Props) => {
    const { pageRef, pageId } = props;

    const [page, setPage] = useState(new Page('', ''));
    const [components, setComponents] = useState<Component[]>([]);

    const [errorLine, setErrorLine] = useState(null);

    const [isPending, setIsPending] = useState(false);
    const [isError, setIsError] = useState(null);

    const { viewHeight, viewWidth, viewBreakpoint } = useWindowDimensions();

    const S3URL = config.S3Region + ".amazonaws.com/" + config.S3Bucket + "/site." + config.site + "/" + config.deployment + "/pages/" + pageRef + ".json";
    const { payload: pageS3, isPending: isPendingS3, error: errorS3 } = useS3Fetch(S3URL);
    // const setFadeIn = (componentReference) => {
    //     var element = document.getElementById(componentReference);
    //     if (element != null) {
    //         element.style.opacity = 1;
    //     }
    // }

    useEffect(() => {

        if (pageS3) {
            //let pageObject = JSON.parse(pageS3) as LoadPageResponseModel;
            let pageObject = pageS3 as LoadPageResponseModel;
            if (pageObject) {
                setPage(pageObject.page);

                let loadedComponents = [] as Component[];
                //Parse components
                pageObject.components.forEach((componentDTO: ComponentDTO) => {
                    let component = JSON.parse(componentDTO.stringifiedComponent);
                    loadedComponents.push(component);
                });

                setComponents(loadedComponents);
            }
        }

        //Call loadpage endpoint

        // const loadPage = async () => {
        //     setIsPending(true);
        //     let response = await apiService.get(config.gridslateUrl + '/cms/LoadPage/' + pageId);
        //     if (response.success) {
        //         setPage(response.payload.page);

        //         let loadedComponents = [] as Component[];
        //         //Parse components
        //         response.payload.components.forEach((componentDTO : ComponentDTO) => {
        //             let component = JSON.parse(componentDTO.stringifiedComponent);
        //             loadedComponents.push(component);
        //         });

        //         setComponents(loadedComponents);
        //         setIsPending(false);
        //     } else {
        //         //setIsError(response.error);
        //         setIsPending(false);
        //     }
        // }

        // loadPage();


    }, [pageS3])


    const renderComponent = (componentRef: string) => {
        let component = components.find(c => c.componentRef === componentRef);
        if (!component) {
            return '';
        }

        switch (component.type) {
            case 'list':
                return <div id={component.componentRef} className={styleLogic.parseStyle(component.style, viewBreakpoint)}>{component.text}</div>;
            case 'title':
                return <h1 id={component.componentRef} style={{color: '#696969'}} className={styleLogic.parseStyle(component.style, viewBreakpoint)}>{component.text}</h1>;
            case 'heading':
                return <h1 id={component.componentRef} style={{color: '#696969'}} className={styleLogic.parseStyle(component.style, viewBreakpoint)}>{component.text}</h1>;
            case 'text':
                return <div>
                    <div id={component.componentRef} style={{color: '#696969'}} className={'whitespace-pre-wrap ' + styleLogic.parseStyle(component.style, viewBreakpoint)} dangerouslySetInnerHTML={{ __html: component.text }} />
                </div>
            case 'image':
                let image = component as Image;
                return <Zoom>
                    <img className={styleLogic.parseImageStyle(component.style, viewBreakpoint)} loading='lazy' alt={image.text} src={image.src}></img>
                </Zoom>
//Bounce, Fade, Flip, Hinge, JackInTheBox, Roll, Rotate, Slide and Zoom
            case 'codeblock':
            // return <CodeTryItViewer originalSourceCode={component.value} componentReference={component.componentRef} errorLine={errorLine} setErrorLine={setErrorLine}></CodeTryItViewer>;
            default:
                return '';
        }
    }

    const getMobilePadding = () => {
        if (viewBreakpoint === 'sm' || viewBreakpoint === 'md') {
            return 'px-2';
        } else {
            return '';
        }
    }

    return (
        <div>

            <Container>

                {/* //Map current Slide gridArry and create a Row and Column for each Component  */}
                {page && page.gridArray.map((row, rowIndex) => {
                    return (
                        <div key={"row" + rowIndex} className={'grid grid-cols-12 ' + getMobilePadding()}>
                            <div className='col-span-12'>
                                <div className={'flex '}>
                                    {row.map((componentRef, colIndex) => {
                                        return (
                                            <div className='flex-1' key={"columns-" + colIndex}>
                                                <Fade triggerOnce fraction={0.3}>
                                                    {renderComponent(componentRef)}
                                                </Fade>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                })}


                {/* {!isPending && !isError && page.gridArray && components && page.gridArray.map((row, index) => (
                    <div key={"page-container-div-" + index}>

                        <Row key={"row" + index} id={"row" + index} className="page-elements">
                            {row.map((gridElement, index2) => (
                                <Col key={"col" + index2} className="">
                                    {renderComponent(gridElement)}
                                </Col>
                            ))}
                        </Row>
                    </div>
                ))} */}

                {isPending &&
                    <div className="text-center">
                        Loading page...
                    </div>}
                {isError &&
                    <div className="text-center">
                        An error has occured! Please refresh the page.
                        {JSON.stringify(isError)}
                    </div>}
            </Container>
        </div>

    );
}

export default ViewPage;