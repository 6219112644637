import {configureStore} from '@reduxjs/toolkit'

import userReducer from './accounts/userReducer';

export const {setDecodedToken, setLoggedIn, resetUser} = userReducer.actions;

export default configureStore({
    reducer: {
      user: userReducer.reducer
    },
  })
