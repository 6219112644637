import useApiGet from "services/useApiGet";
import apiService from "services/apiService";
import { useEffect, useState } from "react";
import { Button } from "flowbite-react";
import { STATUS } from "core/enums/status";
import config from "config.json";
import Page from "classes/gridslate/Page";
import Website from "classes/gridslate/Website";
//import PageDTO from "classes/gridslate/PageDTO";
import { Component, ComponentDTO } from 'classes/content/Components';
import { Modal, ToggleSwitch } from "flowbite-react";
import pageLogic from "core/pageLogic";
import LoadPageResponseModel from "classes/gridslate/LoadPageResponseModel";
import Row from "components/helper/Row";

type Props = {
    // loadWebsite: Function
    website: Website,
    page: Page,
    components : Component[],
    setPageAndComponents: Function
    editPage: Function
}

class CreatePageRequest {
    NewPage: Page;
    NewComponents: ComponentDTO[];

    constructor(NewPage: Page, NewComponents: ComponentDTO[]) {
        this.NewPage = NewPage;
        this.NewComponents = NewComponents;
    }
}

class UpdatePageRequest {
    Page: Page;
    NewComponents: ComponentDTO[];
    UpdateComponents: ComponentDTO[];
    DeleteComponents: string[];

    constructor(Page: Page, NewComponents: ComponentDTO[], UpdateComponents: ComponentDTO[], DeleteComponents: string[]) {
        this.Page = Page;
        this.NewComponents = NewComponents;
        this.UpdateComponents = UpdateComponents;
        this.DeleteComponents = DeleteComponents;
    }

}

const GridSlateSaveLoadPanel = (props: Props) => {

    const { page, components, setPageAndComponents, website, editPage } = props;

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pages, setPages] = useState<Page[]>([]);
    const [isFetchingPages, setIsFetchingPages] = useState<boolean>(true);
    const [selectedPage, setSelectedPage] = useState<number>(-1);

    
    useEffect(() => {
        
        const fetchPages = async () => {
            let response = await apiService.get(config.gridslateUrl + '/cms/GetPages/' + website.id);
            if (response.success) {
                setPages(response.payload);
                setIsFetchingPages(false);
            } else {
                alert('Error loading pages');
                setIsFetchingPages(false);
            }
        }

        if (isFetchingPages){
            console.log('Fetching...');
            fetchPages();
        }

    }, []);


    const handleSavePage = async () => {
        setIsSaving(true);
        if (page.id === ''){
            saveNewPage();
        } else {
            updatePage();
        }
    }

    const saveNewPage = async () => {
        //Create new pageDTO
        //let newPageDTO = pageLogic.clonePageToPageDTO(page);
        let newComponentDTOs = [] as ComponentDTO[];
        //Create new componentDTOs
        components.forEach((component: Component) => {
            newComponentDTOs.push(pageLogic.cloneComponentToComponentDTO(component, page.pageRef, page.id) as ComponentDTO);
        });
        
        //Send to server
        let requestModel = new CreatePageRequest(page, newComponentDTOs);
        let response = await apiService.post(config.gridslateUrl + '/cms/CreateNewPage', requestModel);
        if (response.success) {
            //let newPage = pageLogic.clonePageDTOToPage(response.payload);
            let loadPageResponseModel = new LoadPageResponseModel(response.payload.page, response.payload.components);
            parseAndLoadPage(loadPageResponseModel);
            setIsSaving(false);
        } else {
            setIsSaving(false);
            alert('Error saving page');
        }

    }

    const parseUpdateResponseModel = (newComponents : ComponentDTO[], updateComponents : ComponentDTO[], deleteComponents: string[]) => {
        let allComponents = [...components];
        //For each component in responseModel.NewComponents, find component by componentRef and set id and status
        newComponents.forEach((componentDTO: ComponentDTO) => {
            let component = allComponents.find((c: Component) => c.componentRef === componentDTO.componentRef);
            if (component) {
                component.id = componentDTO.id;
                component.status = STATUS.unchanged;
            }
        });
        //For each component in responseModel.UpdateComponents, find component by componentRef and set status
        updateComponents.forEach((componentDTO: ComponentDTO) => {
            let component = allComponents.find((c: Component) => c.componentRef === componentDTO.componentRef);
            if (component) {
                component.status = STATUS.unchanged;
            }
        });
        //For each component in responseModel.DeleteComponents, find component by componentRef and remove it
        deleteComponents.forEach((componentRef: string) => {
            let index = allComponents.findIndex((c: Component) => c.componentRef === componentRef);
            if (index > -1) {
                newComponents.splice(index, 1);
            }
        });
        return allComponents;
    }

    const updatePage = async () => {
        //Create new pageDTO
        //let newPageDTO = pageLogic.clonePageToPageDTO(page);
        let newComponentDTOs = [] as ComponentDTO[];
        let updatedComponentDTOs = [] as ComponentDTO[];
        let deletedComponentIds = [] as string[];
        //Create new componentDTOs
        components.forEach((component: Component) => {
            if (component.status === STATUS.new) {
                newComponentDTOs.push(pageLogic.cloneComponentToComponentDTO(component, page.pageRef, page.id) as ComponentDTO);
            } else if (component.status === STATUS.updated) {
                updatedComponentDTOs.push(pageLogic.cloneComponentToComponentDTO(component, page.pageRef, page.id) as ComponentDTO);
            } else if (component.status === STATUS.deleted) {
                deletedComponentIds.push(component.id);
            }
        });
        
        //Send to server
        let requestModel = new UpdatePageRequest(page, newComponentDTOs, updatedComponentDTOs, deletedComponentIds);
        let response = await apiService.post(config.gridslateUrl + '/cms/UpdatePage', requestModel);
        if (response.success) {
            let newPage = pageLogic.clonePageDTOToPage(response.payload.page);
            let newComponents = parseUpdateResponseModel(response.payload.newComponents, response.payload.updateComponents, response.payload.deleteComponents);
            setPageAndComponents(newPage, newComponents);
            setIsSaving(false);
        } else {
            setIsSaving(false);
            alert('Error saving page');
        }

    }

    const parseAndLoadPage = (loadPageResponseModel: LoadPageResponseModel) => {
        let loadedPage = loadPageResponseModel.page;
        let loadedComponents = [] as Component[];

        //Parse components
        loadPageResponseModel.components.forEach((componentDTO) => {
            let component = JSON.parse(componentDTO.stringifiedComponent);
            component.status = STATUS.unchanged;
            loadedComponents.push(component);
        });
        
        setPageAndComponents(loadedPage, loadedComponents);
        
    }

    const handleLoadPage = async (pageId: string) => {
        if (isLoading){
            return;
        }
        setIsLoading(true);
        let response = await apiService.get(config.gridslateUrl + '/cms/LoadPage/' + pageId);
        if (response.success) {
            setIsLoading(false);
            parseAndLoadPage(response.payload);
        } else {
            setIsLoading(false);
            alert('Error loading pages');
        }
    }

    return (
        <div className='block'>
            {pages && pages.map((page: Page, index: number) => (
                <div
                    key={index}
                    onClick={() => setSelectedPage(index)}
                    onDoubleClick={() => { handleLoadPage(page.id); setSelectedPage(index) }}
                    className={index === selectedPage ? 'rounded inline-block p-2 m-2 border-2 border-red-500 w-32 h-16 select-none' : 'rounded inline-block p-2 m-2 border-2 border-black w-32 h-16 select-none'}>
                    {page.title}
                </div>
            ))}
            {!pages && <div>Loading...</div>}
            {pages && pages.length === 0 && <div>No pages found</div>}
            <Button className='inline-block m-2' disabled={isSaving} onClick={() => handleSavePage()}>{!isSaving?'Save page': 'Saving page...'}</Button>
            <Button className='inline-block m-2' disabled={selectedPage===-1||isLoading} onClick={() => handleLoadPage(pages[selectedPage].id)}>{!isLoading?'Load page': 'Loading page...'}</Button>
            <Row>
                <input type='text' value={page.title} onChange={(e) => editPage('title', e.target.value)} />
            </Row>
        </div>
    );
};

export default GridSlateSaveLoadPanel;