import { Component } from './Components';

class SelectedComponentData {
    componentRef: string = "";
    rowIndex: number;
    colIndex: number;

    constructor(componentRef: string, rowIndex: number, colIndex: number) {
        this.componentRef = componentRef
        this.rowIndex = rowIndex ? rowIndex : 0;
        this.colIndex = colIndex ? colIndex : 0;
    }
}

export { SelectedComponentData };