//React
import store from './store/store.js';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Components / helpers / services
import UserLogic from './logic/accounts/UserLogic';

//Store

//Views/Components
import MyNavbar from 'views/navbar/MyNavbar';
import Login from 'views/accounts/Login';
import Logout from 'views/accounts/Logout';
//import navbarGrid from 'assets/db/navbar.json';
import GridslateConstructor from 'apps/construct/GridslateConstructor';
import NavbarConstructor from 'apps/NavbarConstructor';
import config from 'config.json';
import useS3Fetch from 'services/useS3Fetch';
import NavbarElement from 'classes/gridslate/NavbarElement';
import NavbarChild from 'classes/gridslate/NavbarChild';
import { useEffect, useState } from 'react';
import React from 'react';
import ViewPage from 'views/page/ViewPage';
import Test from 'views/test/Test';
import Home from 'views/home/Home';

function App() {

  //const { payload: pages, isPending: isPendingP, error: errorP } = useS3Fetch(config.S3BucketURL + '/routes/pages.json');

  const S3URL = config.S3Region + ".amazonaws.com/" + config.S3Bucket + "/site." + config.site + "/" + config.deployment + "/routes/navbar.json";
  const { payload: navbarGridS3, isPending: isPendingNavbar, error: errorNavbar } = useS3Fetch(S3URL);
  const [navbarGrid, setNavbarGrid] = useState<NavbarElement[]>();
  
  useEffect(() => {
    setNavbarGrid(navbarGridS3 as unknown as NavbarElement[]);
  }, [navbarGridS3]);

  // useEffect(() => {
  //   if (navbarGridS3) {
  //     let navbarGrid : NavbarElement[] = [];
  //     navbarGridS3.forEach((element : any) => {
  //       let children : NavbarChild[] = [];
  //       element.children.forEach((child : any) => {
  //         children.push(new NavbarChild(child.websiteId, child.pageRef, child.title, child.route));
  //       });
  //       navbarGrid.push(new NavbarElement(element.websiteId, element.pageRef, element.title, element.route, children));
  //     });
  //     console.log('navbarGrid', navbarGrid);
  //   }
  // }, [navbarGridS3]);

  return (
    <div>

      

      <Provider store={store}>
        <Router>
          <UserLogic />
          <ToastContainer />
          <MyNavbar navbarGrid={navbarGrid} />
          <Routes>

            {navbarGrid && navbarGrid.map((topLevelElement : NavbarElement, index : number) => (
              <React.Fragment key={'navroute_top_' + index}>
                {topLevelElement.children.length === 0 && <Route path={topLevelElement.route} element={<ViewPage pageRef={topLevelElement.pageRef} pageId={topLevelElement.pageId} />} />}
                {topLevelElement.children.length > 0 && topLevelElement.children.map((child : NavbarChild, index2 : number) => (
                  <Route key={'navroute_child_' + index2} path={child.route} element={<ViewPage pageRef={child.pageRef} pageId={child.pageId} />} />
                ))}
              </React.Fragment>
            )
            )}

            <Route path="/" element={<Home />} />

            <Route path="/test" element={<Test />} />
            <Route path="/gridslate/constructor" element={<GridslateConstructor />} />
            <Route path="/gridslate/navbarconstructor" element={<NavbarConstructor />} />
            {/* <Route path="/" element={<Home />} /> */}
            {/* <Route path="/accounts/myaccount" element={<MyAccount />} /> */}
            <Route path="/accounts/login" element={<Login />} />
            <Route path="/accounts/logout" element={<Logout />} />

            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </Router>
      </Provider>
    </div>
  );
}

export default App;