
//import { image, GridLocation } from '../newClasses';
import {Image} from 'classes/content/Components';
import React from 'react';
//React
//import { useDispatch, useSelector } from 'react-redux';

//Helpers/Services
//import { editdata } from 'store/component_actions';

//Views/Components
import Dropzone from 'react-dropzone';

//CSS

type Props = {
  image : Image;
  classString : string;
  style: {};
  setImageAttribute : (targetAttribute: string, value: string) => void;
}

const ImageComponent = (props : Props) => {

  const { image, setImageAttribute, style, classString } = props;

  //const dispatch = useDispatch();
  //const Component = useSelector((state) => state.components[componentRef]);
  // const style = useSelector((state) => state.components[componentRef].style);

  const onDrop = (files : File[]) => {
    console.log("Image onDrop called");
    let localBlobRef = URL.createObjectURL(files[0]);
    setImageAttribute('localBlobRef', localBlobRef);
    //dispatch(editdata({ componentId: componentRef, target: 'localBlobRef', value: localBlobRef }))
  }

  // async function upload(){
  //   console.log("UploadButton clicked");
  //   console.log(data.src)
  //   let newBlob = await fetch(data.src).then(r => r.blob());
  //   let fileFromBlob = new File([newBlob], "default.jpg");

  //   var result = await saveImageToS3(fileFromBlob);
  //   console.log(result);
  //   if (result !== null) {
  //     dispatch(editdata({componentId: componentRef, target: 'src', value: result}))
  //   }

  // }

  return (
    <React.Fragment>
      {!image.src && !image.localBlobRef &&
        <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ className: "image-dropzone" })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            </section>
          )}
        </Dropzone>
      }
      {image.localBlobRef && (!image.src || image.src === 'fetching') && 
          <img src={image.localBlobRef} className={classString} alt={image.text} ></img>
      }
      {image.localBlobRef === '' && image.src &&
          <img crossOrigin="anonymous" src={image.src} alt={image.text} className={'m-auto ' + classString}></img>
      }
    </React.Fragment>

  );

}

export default ImageComponent;

// {image.localBlobRef && (!image.src || image.src === 'fetching') && 
// <img src={image.localBlobRef} className={classString} alt={image.text} ></img>
//   <div className="image-center">
//      <img src={image.localBlobRef} style={style} alt={image.text} ></img> 
//   </div>
// }
// {image.localBlobRef === '' && image.src &&
//   <div className="image-center">
//     <img crossOrigin="anonymous" src={image.src} alt={image.text} style={style}></img>
   
//   </div>
// }