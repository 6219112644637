import NavbarChild from './NavbarChild';

class NavbarElement {

    id: string = "";
    websiteId: string;
    route: string;
    pageId: string;
    pageRef: string;
    title: string;
    children: NavbarChild[];

    constructor(websiteId: string, pageId: string, pageRef: string, title: string, route: string, children: NavbarChild[]) {
        this.websiteId = websiteId;
        this.pageId = pageId;
        this.pageRef = pageRef;
        this.title = title;
        this.route = route;
        this.children = children;
    }
    
}

export default NavbarElement;