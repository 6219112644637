import Page from './Page';
import {ComponentDTO} from 'classes/content/Components';

class LoadPageResponseModel
{
    page : Page;
    components : ComponentDTO[];

    constructor(page: Page, components: ComponentDTO[])
    {
        this.page = page;
        this.components = components;
    }
}

export default LoadPageResponseModel;