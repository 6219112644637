//React
import { useEffect, useState } from 'react';
import { Button } from 'flowbite-react';
import React from 'react';
//import {useParams} from 'react-router-dom';

//Helpers/Services

//Views/Components

//CSS
//import 'css/constructor.css';
//import 'css/slide-constructor.css';

import { COMPONENT_TYPE } from 'core/enums/component-types';
import { STATUS } from 'core/enums/status';
import ComponentRenderer from 'core/ComponentRenderer';

//import { SelectedComponentData, Component, Slide, GridLocation, image, SlideTemplates } from './newClasses';
import { SelectedComponentData } from 'classes/content/SelectedComponentData';
import { Component, Image } from 'classes/content/Components';
import { GridLocation } from 'classes/content/GridLocation';

import componentLogic from 'core/genericComponentLogic';
import ComponentOverviewPanel from './panels/ComponentOverviewPanel';
import StyleOverviewPanel from './panels/StyleOverviewPanel';
import sanitizeLogic from 'utility/logics/sanitizeLogic';

import GridslateSaveLoadPanel from './panels/GridslateSaveLoadPanel';
import requestImageMediaLog from 'services/mediaLogService';
import Page from 'classes/gridslate/Page';
import websiteData from "assets/db/website.json";

import { Row, Col } from 'components/helper/HelperComponents';
//import LoadPageResponseModel from 'classes/gridslate/LoadPageResponseModel';

//shift + Mousewheel increase fontsize and image size

const GridslateConstructor = () => {

    //const [currentSlideshow, setCurrentSlideshow] = useState<Slideshow>(new Slideshow('Test' + createGUID(3)));
    //const [slides, setSlides] = useState<Slide[]>([new Slide(SlideTemplates.empty, 0, 'content')]);
    const [page, setPage] = useState<Page>(new Page('Empty', ''));
    const [components, setComponents] = useState<Component[]>([]);
    const [website, setWebsite] = useState(websiteData);

    const [selectedComponent, setSelectedComponent] = useState<SelectedComponentData>(new SelectedComponentData("", 0, 0));


    // useEffect(() => {

    //     //On document body click event, remove the border from the selected component
    //     document.body.addEventListener('click', (e : MouseEvent) => deselectComponent(e, components));

    //     //remove event listener on unmount
    //     return () => {
    //         document.body.removeEventListener('click', (e : MouseEvent) => deselectComponent(e, components));
    //     }

    // }, [components])

    const deselectElement = (e: React.MouseEvent) => {
        console.log(e.target, e.currentTarget);
        if (e.target !== e.currentTarget) {
            
            return;
        }
        else {
            
            setSelectedComponent(new SelectedComponentData("", 0, 0));
        }
        // let element = e.target as HTMLElement;
        // let elementId = element.getAttribute('id');

        // let component = components.find((component) => component.componentRef === elementId);
        // console.log(component);
        // if (elementId !== null && components.find((component) => component.componentRef === elementId) !== undefined) {
        //     return;
        // } else {
        //     setSelectedComponent(new SelectedComponentData("", 0, 0));
        // }
    }

    //let { websiteId } = useParams();

    //const [selectedSlideshow, setSelectedSlideshow] = useState<string | undefined>('none');

    // useEffect(() => {
    //     let thisGridArray = slides[currentSlideIndex].gridArray;
    //     for (let row in thisGridArray) {
    //         for (let col in thisGridArray[row]) {
    //             let Component = thisGridArray[row][col];
    //             if (Component.type === 'image') {
    //                 let image = Component as image;
    //                 if (image.localBlobRef !== null && image.src === '') {
    //                     let location = new GridLocation(currentSlideIndex, parseInt(row), parseInt(col));
    //                     requestImageMediaLog({image, location, editImageSrc}); 
    //                 }
    //             }
    //         }

    //     }
    // }, [slides[currentSlideIndex].gridArray])


    //STATE HANDLERS ========================================

    // const editImageSrc = (location: GridLocation, src: string) => {
    //     let newSlides = [...slides];
    //     let targetImage = newSlides[location.slideIndex].gridArray[location.rowIndex][location.colIndex] as image;
    //     targetImage.src = src;
    //     newSlides[location.slideIndex].gridArray[location.rowIndex][location.colIndex] = targetImage;
    //     setSlides(newSlides);
    // }

    //Where is this used?
    // const persistSlide = (Slide: Slide) => {
    //     let newSlides = [...slides];
    //     Slide.status = (Slide.status === STATUS.new) ? Slide.status : STATUS.updated;
    //     newSlides[currentSlideIndex] = Slide;
    //     setSlides(newSlides);
    // }

    //const updateComponents = (newComponents: Component[]) => {

    const setComponentText = (componentType: COMPONENT_TYPE, componentRef: string, rowIndex: number, colIndex: number) => {
        let element = document.getElementById(componentRef);
        if (element === null) {
            return;
        }
        let innerText = element.innerHTML;

        if (componentType === COMPONENT_TYPE.text) {
            innerText = sanitizeLogic.cleanTextInnerHtml(innerText);

        }
        if (componentType === COMPONENT_TYPE.list) {
            innerText = sanitizeLogic.cleanListInnerHtml(innerText);
        }

        //update component
        let newComponents = [...components];
        let componentIndex = newComponents.findIndex((component) => component.componentRef === componentRef);
        if (componentIndex === -1) {
            return;
        }
        let targetComponent = newComponents[componentIndex];
        targetComponent.text = innerText;
        targetComponent.status = (targetComponent.status === STATUS.new) ? targetComponent.status : STATUS.updated;
        setComponents(newComponents);
    }

    const setImageAttribute = (location: GridLocation, targetAttribute: string, value: string) => {
        console.log("Reached setImageAttribute:", targetAttribute);
        let componentRef = page.gridArray[location.rowIndex][location.colIndex];
        let componentIndex = components.findIndex((component) => component.componentRef === componentRef);
        if (componentIndex === -1) {
            return;
        }
        let targetImage = { ...components[componentIndex] } as Image;

        //let targetImage = page.gridArray[location.rowIndex][location.colIndex] as Image;
        if (targetImage.type === COMPONENT_TYPE.image) {
            if (targetAttribute === 'src') {
                targetImage.src = value;
            }
            if (targetAttribute === 'localBlobRef') {
                targetImage.localBlobRef = value;
                //let location = new GridLocation(currentSlideIndex, parseInt(row), parseInt(col));
                requestImageMediaLog(targetImage.fileName, targetImage.localBlobRef, location, setImageAttribute);
            }
        }

        //update component
        let newComponents = [...components];
        targetImage.status = (targetImage.status === STATUS.new) ? targetImage.status : STATUS.updated;
        newComponents[componentIndex] = targetImage;
        setComponents(newComponents);
    }

    //COMPONENT HANDLERS ========================================

    const persistGridArrayAndComponents = (gridArray: string[][], components: Component[]) => {
        let newPage = { ...page };
        newPage.gridArray = gridArray;
        setPage(newPage);
        setComponents(components);
    }

    //Adds Component to Slide gridArray
    const handleInsertColumn = () => {
        let gridArray = page.gridArray;
        componentLogic.insertCol({ selectedComponent, components, gridArray, persistGridArrayAndComponents });
    }

    const handleInsertRow = () => {
        let gridArray = page.gridArray;
        componentLogic.insertRow({ selectedComponent, components, gridArray, persistGridArrayAndComponents });
    }

    const handleCloneComponent = () => {
        let gridArray = page.gridArray;
        componentLogic.cloneComponent({ selectedComponent, components, gridArray, persistGridArrayAndComponents });
    }

    const handleDeleteComponent = () => {
        let gridArray = page.gridArray;
        componentLogic.deleteComponent({ selectedComponent, components, gridArray, persistGridArrayAndComponents });
    }

    const handleElementDrop = (componentType: COMPONENT_TYPE, rowIndex: number, colIndex: number) => {
        //Create element and add to gridArray, replacing the empty element
        let newPage = { ...page };
        let newComponents = [...components];
        let newComponent;
        if (componentType === COMPONENT_TYPE.image) {
            newComponent = new Image();
        } else {
            newComponent = new Component(componentType);
        }
        //delete the empty component
        let emptyComponentRef = newPage.gridArray[rowIndex][colIndex];
        let emptyComponentIndex = newComponents.findIndex((component) => component.componentRef === emptyComponentRef);
        if (emptyComponentIndex !== -1) {
            newComponents.splice(emptyComponentIndex, 1);
        }
        newPage.gridArray[rowIndex][colIndex] = newComponent.componentRef;
        newComponents.push(newComponent);
        setComponents(newComponents);
        setPage(newPage);

    }

    //TOOLPANE RENDERERS ========================================

    const GridElementToolpane = () => {
        return (
            <React.Fragment>
                <Button size='xs' className='m-1' onClick={() => handleInsertColumn()}>Add Col</Button>
                <Button size='xs' className='m-1' onClick={() => handleInsertRow()}>Insert Row</Button>
                <Button size='xs' className='m-1' onClick={() => handleCloneComponent()}>Clone</Button>
                <Button size='xs' className='m-1' onClick={() => handleDeleteComponent()}>Del</Button>
            </React.Fragment>
        )
    }

    //TEST HANDLERS ========================================



    //RENDER ========================================

    const handleCreateNewPage = () => {
        let newPage = new Page(website.id, 'New Page');
        let newComponents = [];

        //Add three new empty rows containing empty components
        for (let i = 0; i < 3; i++) {
            let newEmptyComponent = new Component(COMPONENT_TYPE.empty);
            newComponents.push(newEmptyComponent);
            newPage.gridArray.push([newEmptyComponent.componentRef]);
        }
        setComponents(newComponents);
        setPage(newPage);
    }

    const setPageAndComponents = (newPage: Page, newComponents: Component[]) => {
        setPage(newPage);
        setComponents(newComponents);
    }

    //Creating Page from docX
    // const handleDocXUpload = (files) => {

    //     let fd = new FormData();
    //     fd.append('file', files[0]);

    //     http.upload(config.apiUrl + '/cms/uploadDocXFile', fd)
    //         .then((data) => {
    //             if (!data.errorCaught) {
    //                 let parsedPageData = createPageFromDocXData(data.payload);
    //                 dispatch(loadComponents({ components: parsedPageData.components }));
    //                 dispatch(setPage({ page: parsedPageData.page }));
    //                 dispatch(setGridObject({ grid: parsedPageData.grid }));
    //             } else {
    //                 processHttpError(data);
    //             }
    //         })
    // }

    const editPage = (target: string, value: string) => {
        let newPage = { ...page };
        if (target === 'title') {
            newPage.title = value;
        }
        setPage(newPage);
    }

    const getComponentStyle = (componentRef: string) => {
        let component = components.find((component) => component.componentRef === componentRef);
        if (component) {
            return component.style;
        } else {
            return {};
        }
    }

    const setComponentStyle = (componentRef: string, style: {}) => {
        let newComponents = [...components];
        let componentIndex = newComponents.findIndex((component) => component.componentRef === componentRef);
        if (componentIndex === -1) {
            console.log("No component found");
            return;
        }
        newComponents[componentIndex].style = { ...style };
        newComponents[componentIndex].status = (newComponents[componentIndex].status === STATUS.new) ? newComponents[componentIndex].status : STATUS.updated;
        setComponents(newComponents);
    }

    const loseFocusHandler = (componentRef: string, rowIndex: number, colIndex: number) => {
        let element = document.getElementById(componentRef) as HTMLElement;
        try {
            element.blur();
        } catch (e) {
            console.log("Element not found");
        }
    }

    return (
        <div className='container mx-auto'>
            <div className='inline-block w-full my-2'>
                <GridslateSaveLoadPanel
                    page={page}
                    components={components}
                    setPageAndComponents={setPageAndComponents}
                    website={website}
                    editPage={editPage}
                />
            </div>
            <Row>
                <div>
                    <Button onClick={() => handleCreateNewPage()}>Create New Page</Button>
                </div>
            </Row>
            <div className='sticky top-0'>
                <ComponentOverviewPanel />
                {selectedComponent.componentRef != "" && <StyleOverviewPanel
                    selectedComponent={selectedComponent}
                    componentStyle={getComponentStyle(selectedComponent.componentRef)}
                    setComponentStyle={setComponentStyle}
                />}
            </div>

            <Row>
                <div className='container mx-auto' onClick={(e: React.MouseEvent) => deselectElement(e)}>
                    {/* //Map current Slide gridArry and create a Row and Column for each Component  */}
                    {page && page.gridArray.map((row, rowIndex) => {
                        return (
                            <div key={"row" + rowIndex} className='grid grid-cols-12'>
                                <div className='col-span-11'>
                                    <div className='flex gap-2'>
                                        {row.map((componentRef, colIndex) => {
                                            return (
                                                <div className='flex-1' key={"columns-" + colIndex}>
                                                    {/* //Create a contenteditable div for each Component */}
                                                    <div
                                                        onClick={() => setSelectedComponent(new SelectedComponentData(componentRef, rowIndex, colIndex))}
                                                        className={selectedComponent.componentRef === componentRef ? 'border-4 border-red-400' : ''}
                                                        onMouseLeave={() => loseFocusHandler(componentRef, rowIndex, colIndex)}
                                                        role="content"
                                                    >
                                                        <ComponentRenderer
                                                            Component={components.find((component) => component.componentRef === componentRef) as Component}
                                                            rowIndex={rowIndex}
                                                            colIndex={colIndex}
                                                            setComponentTextHandler={(componentType: COMPONENT_TYPE) => setComponentText(componentType, componentRef, rowIndex, colIndex)}
                                                            handleDrop={(componentType: COMPONENT_TYPE, rowIndex: number, colIndex: number) => handleElementDrop(componentType, rowIndex, colIndex)}
                                                            setImageAttribute={(targetAttribute: string, value: string) => setImageAttribute(new GridLocation(0, rowIndex, colIndex), targetAttribute, value)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className=''>
                                    {selectedComponent.rowIndex === rowIndex && <GridElementToolpane />}
                                </div>
                            </div>
                        )
                    })}

                    {/* {JSON.stringify(selectedComponent)}
                    {JSON.stringify(components.find((component) => component.componentRef === selectedComponent.componentRef))} */}
                    {/* //Centered button to add new row */}
                    <Row className='justify-center'>
                        <Button className='m-1' onClick={() => {
                            let gridArray = page.gridArray;
                            componentLogic.addRow({ selectedComponent, components, gridArray, persistGridArrayAndComponents })
                        }}>Add Row</Button>
                    </Row>
                </div>

            </Row>


        </div>
    )

}

export default GridslateConstructor;