
//React
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Button, TextInput, Label, Checkbox } from "flowbite-react";

//Helpers/Services
import config from 'config.json';
import apiService from "services/apiService";
import { setDecodedToken } from "store/store";
import { decodeToken } from "services/tokenService";
import errorService from "services/errorService";

const Login = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  //const [keepLoggedIn, setKeepLoggedIn] = useState(true);
  const [isPending, setIsPending] = useState(false);
  //const [serverIsOnline, setServerIsOnline] = useState(null);
  //let { redirect } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const redirect = urlParams.get('redirect');

  // useEffect(() => {
  //   console.log(redirect);
  // })

  const handleLogin = async (e: any) => {
    //e : React.MouseEvent<HTMLButtonElement>
    e.preventDefault();

    setIsPending(true);

    await apiService.post(config.gridslateUrl + '/accounts/signin', { "Username": username, "Password": password })

      .then((response) => {
        console.log(response.payload);
        if (response.success) {

          console.log(response.payload.accessToken);

          localStorage.setItem("accessToken", response.payload.accessToken);
          localStorage.setItem("refreshToken", response.payload.refreshToken);
          localStorage.setItem("expirationTime", response.payload.expirationTime);

          let user = decodeToken();

          if (user !== null) {
            dispatch(setDecodedToken({ userToken: user }));
          }

          setIsPending(false);
          if (redirect) {
            navigate(-1);
          } else {
            navigate("/");
          }

        } else {
          errorService.handleError(response);
          //toast.error(response.message);
          setPassword('');
          setIsPending(false);
        }
      });

  };

  return (
    <div className='container mx-auto'>
      {/* <div style={{ width: "5px", height: "5px", backgroundColor: serverIsOnline ? "green" : "red" }}></div> */}
      <form className="flex max-w-md flex-col gap-4">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email1" value="Your email" />
          </div>
          <TextInput id="email1" type="email" placeholder="Your username" required value={username}
            onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="password1" value="Your password" />
          </div>
          <TextInput id="password1" type="password" required value={password}
            onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div className="flex items-center gap-2">
          <Checkbox id="remember" />
          <Label htmlFor="remember">Remember me</Label>
        </div>
        <Button type="submit" onClick={!isPending ? (e: any) => handleLogin(e) : () => { }} disabled={isPending}
        >
          {isPending ? 'Signing in…' : 'Sign in'}
        </Button>
      </form>
    </div>
  );

}

export default Login;