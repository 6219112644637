//React
import Container from "components/helper/Container";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

//Helpers/Services
//import useS3Fetch from "services/useS3Fetch";
//import config from 'config.js';

//Views/Components

//CSS
//import "./_home.css";

const Home = () => {

    const navigate = useNavigate();
    //const user = useSelector((state) => state.user);

    //const { payload: courses, isPending, error } = useS3Fetch(config.S3BucketURL + '/courses/courses.json');
   

    return (
        <Container>
            <div>
                <Fade>
               <img className='my-12 m-auto shadow-2xl shadow-blue-700 w-11/12 md:w-7/12' src={require('assets/images/Bubble.jpg')} alt='bubble' onClick={() => navigate('/currentwork')}/>
               </Fade>
            
            </div>
        </Container>
    );
}

export default Home;

