//import { COMPONENT_TYPE } from 'core/enums/component-types';
//import { STATUS } from 'core/enums/status';
import { Component, ComponentDTO } from 'classes/content/Components';
//import { SelectedComponentData } from 'classes/content/SelectedComponentData';
import Page from 'classes/gridslate/Page';
import PageDTO from 'classes/gridslate/PageDTO';

// type Props = {
//     selectedComponent: SelectedComponentData;
//     components: Component[];
//     gridArray: string[][];
//     persistGridArrayAndComponents: Function;
// }

const clonePageToPageDTO = (page: Page) => {

    let pageDTO = new PageDTO();

    pageDTO.id = page.id;
    pageDTO.parentId = page.parentId;
    pageDTO.title = page.title;
    pageDTO.pageRef = page.pageRef;
    pageDTO.description = page.description;
    pageDTO.ownerId = page.ownerId;
    pageDTO.creationDate = page.creationDate;
    pageDTO.lastUpdateDate = page.lastUpdateDate;
    pageDTO.gridArray = page.gridArray;

    return pageDTO;
}

const cloneComponentToComponentDTO = (component: Component, pageRef: string, pageId: string) => {

    let componentDTO = new ComponentDTO();
    componentDTO.id = component.id;
    componentDTO.pageRef = pageRef;
    componentDTO.pageId = pageId;
    componentDTO.componentRef = component.componentRef;
    componentDTO.stringifiedComponent = JSON.stringify(component);
    componentDTO.type = component.type;
    return componentDTO;
}

const clonePageDTOToPage = (pageDTO: PageDTO) => {
    
        let page = new Page(pageDTO.parentId, pageDTO.title);
        page.id = pageDTO.id;
        page.pageRef = pageDTO.pageRef;
        page.description = pageDTO.description;
        page.ownerId = pageDTO.ownerId;
        page.creationDate = pageDTO.creationDate;
        page.lastUpdateDate = pageDTO.lastUpdateDate;
        page.gridArray = pageDTO.gridArray;

        return page;
    }


export default { clonePageToPageDTO, cloneComponentToComponentDTO, clonePageDTOToPage };