
class PageDTO
{
    //public List<List<GridElement>> GridArray { get; set; }

    id : string = "";
    parentId : string = "";
    title : string = "";
    pageRef : string = "";
    description : string = "";
    ownerId: string = "";
    creationDate: string = "";
    lastUpdateDate: string = "";
    gridArray : string[][] = [];

    constructor()
    {
        
    }

}

export default PageDTO;