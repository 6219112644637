import { Link } from "react-router-dom";
import React from "react";
import { Avatar, Navbar, Dropdown, ToggleSwitch } from "flowbite-react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import config from "config.json";

const MyNavbar = ({ navbarGrid }) => {
  //const navbarGrid = props.navbarGrid;
  const user = useSelector((state) => state.user);
  const [darkMode, setDarkMode] = useState('light');

  useEffect(() => {
    setDarkModeFromStorage();
    document.title = config.siteTitle;
  }, [user]);

  const setDarkModeFromStorage = () => {
    if (localStorage.getItem('color-theme')) {
      setDarkMode(localStorage.getItem('color-theme'));
      // if NOT set via local storage previously
    } else {
      // if user prefers dark mode
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        //Matching dark mode disabled
        //setDarkMode('dark');
        //localStorage.setItem('color-theme', 'dark');
      } else {
        setDarkMode('light');
        localStorage.setItem('color-theme', 'light');
      }
    }
  }

  const toggleDarkMode = () => {
    if (darkMode === 'dark') {
      setDarkMode('light');
      localStorage.setItem('color-theme', 'light');
      document.documentElement.classList.add('dark');
    } else {
      setDarkMode('dark');
      localStorage.setItem('color-theme', 'dark');
      document.documentElement.classList.remove('dark');
    }
  }

  return (

    <div className='container mx-auto'>

      <Navbar fluid rounded className='bg-slate-50 rounded border-2'>
        <Navbar.Brand href="/">
          {/* <img src="/favicon.svg" className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" /> */}
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white" style={{color: '#696969'}}>
            {config.siteTitle}
          </span>
        </Navbar.Brand>

        <Navbar.Collapse>

          {/* <Link to={"/"} >Home</Link> */}

          {navbarGrid && navbarGrid.map((item, index1) => (
            <React.Fragment key={"navdiv" + index1}>
              {item.pageRef !== "dropdown" && <Navbar.Link className='md:text-lg'  style={{color: '#696969'}} href={"/" + item.route}>{item.title}</Navbar.Link>}
              {item.pageRef === "dropdown" &&
                <Dropdown
                  label={item.title}
                  arrowIcon={true}
                  inline
                >
                  {item.children.map((child, index2) => (
                    <Dropdown.Item href={"/" + child.route} key={"navbar" + index1 + "-" + index2}>{child.title}</Dropdown.Item>
                  ))
                  }
                </Dropdown>
              }

            </React.Fragment>
          ))}
        </Navbar.Collapse>
        {!user.loggedIn &&
          <div>
            <Link to={"/accounts/login"} className='text-slate-400'>Log in</Link>
          </div>}
        {user.loggedIn && user.userToken && user.userToken.username &&
          <Dropdown
            arrowIcon={false}
            inline
            label={
              <Avatar alt="User settings" placeholderInitials="CJ" rounded />
            }
          >
            <Dropdown.Header>
              <span className="block text-sm">{user.userToken.username}</span>
            </Dropdown.Header>

            {/* <Dropdown.Item href={"/accounts/myaccount"}>My Account</Dropdown.Item> */}
            {/* <Dropdown.Item href={"/teststuff"}>Test Stuff</Dropdown.Item> */}
            <Dropdown.Item href={"/gridslate/constructor"}>Gridslate</Dropdown.Item>
            <Dropdown.Item href={"/gridslate/navbarconstructor"}>Navbar</Dropdown.Item>

            <Dropdown.Divider />
            <Dropdown.Item href={"/accounts/logout"}>Sign out</Dropdown.Item>
          </Dropdown>
        }
        {/* <ToggleSwitch checked={darkMode === 'dark' ? true : false} label="Darkmode" onChange={() => toggleDarkMode()} /> */}
       
        <Navbar.Toggle />
      </Navbar>

    </div>
  );
}

export default MyNavbar;