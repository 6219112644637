//React
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//Helpers/Services
import { setLoggedIn } from "store/store";

//Views/Components

const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        //Todo: logout on server
        dispatch(setLoggedIn({ loggedIn: false }));
        localStorage.removeItem("accessToken");
        navigate("/");
    });

    return (
        <div className='container mx-auto'>
            <h1>Logging out...</h1>
        </div>
    );
}

export default Logout;