class GridLocation {
    slideIndex: number;
    rowIndex: number;
    colIndex: number;

    constructor(slideIndex: number, rowIndex: number, colIndex: number) {
        this.slideIndex = slideIndex;
        this.rowIndex = rowIndex;
        this.colIndex = colIndex;
    }
}

export { GridLocation };
