
enum COMPONENT_TYPE {
    text = "text",
    image = "image",
    codeblock = "codeblock",
    title = "title",
    heading = "heading",
    empty = "empty",
    list = "list",
    mcqQuestion = "mcqQuestion",
    mcqAnswer = "mcqAnswer"
}

export {COMPONENT_TYPE};