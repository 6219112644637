
import { COMPONENT_TYPE } from 'core/enums/component-types';
import StyleSetting from 'classes/style/StyleSetting';
import StyleItemOption from 'classes/style/StyleItemOption';
import tailwindClassLogic from './tailwindClassLogic';
//import { Slide } from 'classes/slideshow-constructor/Slides';
//import { SelectedComponentData } from 'classes/content/SelectedComponentData';

// type Props = {
//     selectedComponent: SelectedComponentData;
//     Slide: Slide;
//     persistSlide: (Slide: Slide) => void;

// }
//, textTransform: 'uppercase'
//return a suggested default style for each Component type

const returnStyleItemOptions = () => {
    let styleItemOptions = [] as StyleItemOption[];
    styleItemOptions.push(new StyleItemOption("fontSize", ["xs", "sm", "base", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "6xl"], "base", "slider"));
    styleItemOptions.push(new StyleItemOption("color", [], "#000000", "colorpicker"));
    styleItemOptions.push(new StyleItemOption("background", [], "#000000", "colorpicker"));
    styleItemOptions.push(new StyleItemOption("bold", ["none", "bold"], "none", "click"));
    styleItemOptions.push(new StyleItemOption("italics", ["none", "italic"], "none", "click"));
    styleItemOptions.push(new StyleItemOption("decoration", ["none", "underline", "overline", "line-through"], "none", "click"));
    styleItemOptions.push(new StyleItemOption("alignment", ["none", "center"], "none", "click"));
    styleItemOptions.push(new StyleItemOption("margin", ['0', '1', '2', '3', '4', '5', '6', '8', '10', '12', '16', '20', '24', '32', '40', '48', '56', '64'], "3", "slider"));
    styleItemOptions.push(new StyleItemOption("padding", ["0", "5", "10", "15", "20"], "0", "slider"));
    styleItemOptions.push(new StyleItemOption("border", ["0", "1", "2", "3", "4"], "0", "slider"));
    styleItemOptions.push(new StyleItemOption("width", ["fit", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"], "fit", "slider"));
    styleItemOptions.push(new StyleItemOption("height", ["fit", "1", "2", "3", "4", "5", "6"], "fit", "slider"));
    return styleItemOptions;
}

const getDefaultStyle = (type: COMPONENT_TYPE) => {
    let style = {};
    // if (type === COMPONENT_TYPE.text) {
    //     style = { fontSize: '20px', color: 'black' };
    // }
    // if (type === COMPONENT_TYPE.empty) {
    //     style = { backgroundColor: 'lightgray' };
    // }
    // if (type === COMPONENT_TYPE.image) {
    //     style = { width: '100%', overflow: 'hidden' };
    // }
    // if (type === COMPONENT_TYPE.title) {
    //     style = { fontSize: '30px', fontWeight: 'bold', color: 'black', textAlign: 'center', textTransform: 'uppercase' };
    // }
    // if (type === COMPONENT_TYPE.heading) {
    //     style = { fontSize: '25px', color: 'black', textAlign: 'center' };
    // }
    // if (type === COMPONENT_TYPE.mcqQuestion) {
    //     style = { fontSize: '25px', color: 'black', backgroundColor: 'lightgray', textAlign: 'center' };
    // }
    // if (type === COMPONENT_TYPE.mcqAnswer) {
    //     style = { fontSize: '20px', color: 'black', backgroundColor: 'lightgray' };
    // }
    return style;
}

const setDefaultStyle = (type: COMPONENT_TYPE) => {
    let style: StyleSetting = {};
    if (type === COMPONENT_TYPE.text) {
        //style = { fontSize: '20px', color: 'black' };

        style['fontSize'] = 'base';
        style['background'] = 'none';
        style['color'] = '#000000';
        style['bold'] = 'none';
        style['decoration'] = 'none';
        style['italic'] = 'none';
        style['alignment'] = 'none';
        style['margin'] = '3';
        style['padding'] = '0';
        style['border'] = '0';
        style['width'] = 'fit';
        style['height'] = 'fit';
    }
    if (type === COMPONENT_TYPE.empty) {
        //style = { backgroundColor: 'lightgray' };
        style['fontSize'] = 'base';
        style['color'] = '#000000';
        style['background'] = 'lightgray';
        style['bold'] = 'none';
        style['decoration'] = 'none';
        style['italic'] = 'none';
        style['alignment'] = 'none';
        style['margin'] = '0';
        style['padding'] = '0';
        style['border'] = '0';
        style['width'] = 'fit';
        style['height'] = 'fit';
    }
    if (type === COMPONENT_TYPE.image) {
        //style = { width: '100%', overflow: 'hidden'};
        style['fontSize'] = 'base';
        style['color'] = '#000000';
        style['background'] = 'none';
        style['bold'] = 'none';
        style['decoration'] = 'none';
        style['italic'] = 'none';
        style['alignment'] = 'center';
        style['margin'] = '0';
        style['padding'] = '0';
        style['border'] = '0';
        style['width'] = '11/12';
        style['height'] = 'fit';
    }
    if (type === COMPONENT_TYPE.title) {
        //style = { fontSize: '30px', fontWeight:'bold', color: 'black', textAlign: 'center', textTransform: 'uppercase' };
        style['fontSize'] = '2xl';
        style['color'] = '#000000';
        style['background'] = 'none';
        style['bold'] = 'bold';
        style['decoration'] = 'none';
        style['italic'] = 'none';
        style['alignment'] = 'center';
        style['margin'] = '3';
        style['padding'] = '0';
        style['border'] = '0';
        style['width'] = 'fit';
        style['height'] = 'fit';
    }
    if (type === COMPONENT_TYPE.heading) {
        //style = { fontSize: '25px', color: 'black', textAlign: 'center' };
        style['fontSize'] = 'xl';
        style['color'] = '#000000';
        style['background'] = 'none';
        style['bold'] = 'bold';
        style['decoration'] = 'decoration';
        style['italic'] = 'none';
        style['alignment'] = 'center';
        style['margin'] = '3';
        style['padding'] = '0';
        style['border'] = '0';
        style['width'] = 'fit';
        style['height'] = 'fit';
    }
    // if (type === COMPONENT_TYPE.mcqQuestion) {
    //     style = { fontSize: '25px', color: 'black', backgroundColor: 'lightgray', textAlign: 'center' };
    // }
    // if (type === COMPONENT_TYPE.mcqAnswer) {
    //     style = { fontSize: '20px', color: 'black', backgroundColor: 'lightgray' };
    // }
    return style;
}



const parseStyle = (style: StyleSetting, breakpoint: string) => {
    //Parse style object and build a tailwind class string
    let styleString = ' ';
    for (const [key, value] of Object.entries(style)) {
        if (key === 'fontSize') {
            styleString += tailwindClassLogic.returnFontSizeClass(value, breakpoint);
        }
        if (key === 'color') {
            styleString += tailwindClassLogic.returnColorClass(value);
        }
        if (key === 'background' && value != 'none') {
            styleString += tailwindClassLogic.returnBackgroundClass(value); 
        }
        if (key === 'bold' && value === 'bold') {
            styleString += tailwindClassLogic.returnBoldClass(value);
        }
        if (key === 'decoration' && value != 'none') {
            styleString += tailwindClassLogic.returnDecorationClass(value);
        }
        if (key === 'italic' && value === 'italic') {
            styleString += tailwindClassLogic.returnItalicClass(value);
        }
        if (key === 'alignment') {
            styleString += tailwindClassLogic.returnAlignmentClass(value);
        }
        if (key === 'margin') {
            styleString += tailwindClassLogic.returnMarginClass(value);
        }
        if (key === 'padding') {
            styleString += tailwindClassLogic.returnPaddingClass(value);            
        }
        if (key === 'border') {
            //styleString += 'border-' + value + ' ';
        }
        if (key === 'width') {
            styleString += tailwindClassLogic.returnWidthClass(value, breakpoint);
           
        }
        if (key === 'height') {
            styleString += tailwindClassLogic.returnHeightClass(value);
        }
    }
    //console.log(styleString);
    return styleString;
}

const parseImageStyle = (style: StyleSetting, viewBreakpoint : string) => {
    let styleString = 'm-auto shadow-2xl ';
    for (const [key, value] of Object.entries(style)) {
        
        if (key === 'width') {
            styleString += tailwindClassLogic.returnWidthClass(value, viewBreakpoint);
            // if (viewBreakpoint === 'sm' || viewBreakpoint === 'md' ) {
            //     styleString += ' px-2 ';
            // }   
        }        
    }
    //console.log(styleString);
    return styleString;
}


export default { getDefaultStyle, setDefaultStyle, parseStyle, parseImageStyle, returnStyleItemOptions };