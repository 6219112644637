import {createSlice} from '@reduxjs/toolkit'

const userReducer = createSlice({
    name: "user",
    initialState: {
      "userToken": {},
      "loggedIn": false,
      "username": "",
      "email": "",
      "claims": "",
      "refreshExpirationTime" : ""
    },
    reducers: {
      setDecodedToken: (state, action) => {
        state.userToken = action.payload.userToken;
        state.username = action.payload.userToken.username;
        state.email = action.payload.userToken.email;
        state.loggedIn = true;
      },
      resetUser: (state, action) => {
        state.loggedIn = false;
        state.userToken = {};
        // return {
        //   "userToken": {},
        //   "loggedIn": false      
        // };
      },
      setLoggedIn: (state, action) => {       
        state.loggedIn = action.payload.loggedIn;
      }
    }
  });

  export default userReducer;